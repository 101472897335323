<template>
  <svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.53906 3.65527V6.15527M6.53906 8.65527H6.54531M3.91406 11.7803H9.16406C10.2142 11.7803 10.7392 11.7803 11.1403 11.5759C11.4931 11.3961 11.7799 11.1093 11.9597 10.7565C12.1641 10.3554 12.1641 9.83037 12.1641 8.78027V3.53027C12.1641 2.48018 12.1641 1.95513 11.9597 1.55404C11.7799 1.20124 11.4931 0.914399 11.1403 0.734636C10.7392 0.530273 10.2142 0.530273 9.16406 0.530273H3.91406C2.86396 0.530273 2.33891 0.530273 1.93783 0.734636C1.58503 0.914399 1.29819 1.20124 1.11843 1.55404C0.914062 1.95513 0.914062 2.48017 0.914062 3.53027V8.78027C0.914062 9.83037 0.914062 10.3554 1.11843 10.7565C1.29819 11.1093 1.58503 11.3961 1.93783 11.5759C2.33891 11.7803 2.86396 11.7803 3.91406 11.7803Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSignOut'
}
</script>
